import {
  useCachedClients,
  useCachedReports,
  useCachedWorkspaceMembership,
} from '@easy-expense/data-firestore-client';
import {
  ClientValueSchema,
  Expense,
  Income,
  ReportWithData,
  Trip,
} from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTripDeduction } from '@easy-expense/utils-shared';
import _, { max, min, sumBy } from 'lodash';
import React from 'react';

export function useReportsRows(): ReportWithData[] {
  const reports = useCachedReports();
  const clients = useCachedClients();
  const membership = useCachedWorkspaceMembership();
  const expenses = Data.expenses.use();
  const incomes = Data.incomes.use();
  const trips = Data.trips.use();
  const tripClasses = Data.tripClasses.use();

  return React.useMemo(() => {
    const res: ReportWithData[] = [
      ...reports.map((report) => {
        const canEdit =
          report.createdBy === membership?.userID ||
          membership?.role === 'manager' ||
          membership?.role === 'admin';

        const reportExpenses: Expense[] = report.expenses.reduce((acc: Expense[], expenseKey) => {
          const expense = Data.expenses.getByKey(expenseKey);
          if (expense && expense.entityState === 'active') {
            acc.push(expense);
          }
          return acc;
        }, []);

        const reportIncomes: Income[] = report.incomes.reduce((acc: Income[], incomeKey) => {
          const income = Data.incomes.getByKey(incomeKey);
          if (income && income.entityState === 'active') {
            acc.push(income);
          }
          return acc;
        }, []);

        const reportTrips: Trip[] = _.compact(
          report.trips.map((tripKey) => Data.trips.getByKey(tripKey)),
        );

        const dates = [...reportExpenses, ...reportTrips].map((t) => t.date);
        const startDate = min(dates);
        const endDate = max(dates);
        const incomeTotal = sumBy(reportIncomes, 'total');
        const expenseTotal = sumBy(reportExpenses, 'total');
        const tripTotal = sumBy(reportTrips, (trip) =>
          getTripDeduction(trip, tripClasses?.find((tc) => tc.key === trip.tripClass) ?? undefined),
        );
        const total = incomeTotal - expenseTotal - tripTotal;

        const clientValueParse = ClientValueSchema.safeParse(
          clients.find((c) => c.key === report.client)?.value,
        );
        const clientValue = clientValueParse.success ? clientValueParse.data : undefined;

        return {
          ...report,
          reportExpenses,
          reportIncomes,
          reportTrips,
          startDate,
          endDate,
          incomeTotal,
          expenseTotal,
          tripTotal,
          total,
          clientValue,
          canEdit,
        };
      }),
    ];

    return res;
  }, [reports, clients, membership, expenses, incomes, trips]);
}
